.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #f7f7f7;
    color: #333;
    font-family: 'Arial', sans-serif;
  }

  .header-container {
    width: 100%;
    flex-direction: row;
  }

  .page-content {
    flex-grow: 1;
    width: 100%;
    text-align: center;
    justify-content: space-between;
  }

  .page-item {

  }

  .page-carousel {
    
  }

  .page-text {
    font-size: 1.2em;
  }

  .footer-container {

  }