
  .carousel {
    width: 100%;
    margin: 0 auto;
  }

  .carousel-image {
    width: auto;
    height: auto;
    max-height: 1200px;
    max-width: auto;
  }