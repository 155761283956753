.header {
    background-color: #007bff
  }
  
  .header-title {
    margin: 0;
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    display: flex;
    padding-left: 10px;
  }

  .header-logo {
    margin: 0;
    font-family: 'Arial', sans-serif;
    font-size: 30px;
    margin-right: 100px;
  }

  .header-logo-title {
    text-decoration: none;
    color: white;
  }

  .header-group {
    display: flex;
    margin-right: 15px;
  }

  .header-navbar {
    padding-left: 10px;
  }
  
  /* Add more styles as needed */
  