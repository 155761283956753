.card {
    height: rem(440px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-size: cover;
    background-position: center;
  }
  
  .title {
    font-weight: 900;
    color: var(--mantine-color-white);
    line-height: 1.2;
    font-size: rem(32px);
    margin-top: var(--mantine-spacing-xs);
    cursor: default;
  }
  
  .category {
    color: var(--mantine-color-white);
    opacity: 0.7;
    font-weight: 700;
    text-transform: uppercase;
    cursor: default;
  }

  .description {
    font-weight: 800;
    color: var(--mantine-color-white);
    font-size: rem(20px);
    cursor: default;
  }

  .card-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    filter: blur(3px);
    transition: filter 0.3s;
  }

  .card-image:hover {
    filter: blur(0);
  }

  .card-image:hover > background-image {
    opacity: 1;
  }