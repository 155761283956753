.footer {
    display: flex;
    width: 100%;
    background-color: var(--mantine-color-blue-light);
}

.footer-inner {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;

    @media (max-width: $mantine-breakpoint-sm) {
        flex-direction: column;
        align-items: center;
    }
}