.nav-container {
    background-color: #007bff; /* Blue background */
    overflow: hidden;
  }
  
  .nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center; /* Center the navigation items */
  }
  
  .nav-item {
    display: inline-block;
    margin-right: 20px; /* Space between items */
  }
  
  .nav-item:last-child {
    margin-right: 0; /* No margin for the last item */
  }
  
  .nav-link {
    display: block;
    color: white; /* White text for readability */
    text-align: center;
    padding: 10px 10px;
    text-decoration: none;
    margin-right: 10px;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .nav-link:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  