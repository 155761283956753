.carousel-image {
    width: auto;
    height: 600px;
    object-fit: contain;
}

.carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}